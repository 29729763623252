.mapContainer {
    position: relative;

    .mapContainer__bg {
        height: 374px;
        background: url(../../../../assets/about/map_banner.png) no-repeat;
        background-size: cover;

        .mapContainer__footer {
            position: absolute;
            left: 50%;
            bottom: 40px;
            width: 800px;
            margin-left: -400px;
            text-align: center;
            font-size: 14px;
            color: #FFFFFF;
        }

        .lightsWrap {
            height: 100%;

            .light__iconGreen {
                position: absolute;
                width: 28px;
                height: 28px;
                background: url(../../../../assets/about/light_green.png) no-repeat;
                background-size: cover;
                opacity: .6;
                transition: all .8s ease-in-out;

                &.light {
                    opacity: 1;
                }
            }

            .light__iconWhite {
                position: absolute;
                width: 50px;
                height: 50px;
                background: url(../../../../assets/about/light_white.png) no-repeat;
                background-size: cover;
                transition: all 1.5s ease-in-out;
                opacity: .7;

                &.light {
                    opacity: 1;
                }
            }

            .light__iconGolden {
                position: absolute;
                width: 50px;
                height: 50px;
                background: url(../../../../assets/about/light_golden.png) no-repeat;
                background-size: cover;
                opacity: .8;
                transition: all 1s ease-in-out;

                &.light {
                    opacity: 1;
                }
            }

            .text__pos {
                position: absolute;
                font-size: 12px;
                color: #fff;
                transform: scale(.8, .8);
            }
        }
    }

}

@media screen and (max-width:1280px) {
    .mapContainer {
        .mapContainer__bg {
            height: 250px;
            .mapContainer__footer {
                left: 50%;
                bottom: 25px;
                width: 600px;
                margin-left: -300px;
                font-size: 12px;
                &.en{
                 width: 800px;
                 margin-left: -400px;
               }
            }
        }

    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
     .mapContainer {
        .mapContainer__bg {
            height: 300px;
            .mapContainer__footer {
                left: 50%;
                bottom: 30px;
                width: 720px;
                margin-left: -360px;
                font-size: 15px;
                &.en{
                 width: 1000px;
                 margin-left: -500px;
               }
            }
        }

    }
}