.homePage__ramonsterWrap {
    .homePage__ramonsterSlogan {
        .homePage__ramonsterSlogan-img {
            width: 1172px;
            height: auto;
            z-index: -1;
        }
    }

    .homePage__ramonsterRow {
        margin-top: -60px;
        display: flex;

        .homePage__ramonsterLeft {
            margin-left: 50px;

            .homePage__ramonsterLeft-image {
                width: 550px;
                height: auto;
            }
        }

        .homePage__ramonsterRight {
            margin-left: 100px;
            margin-right: 15px;

            .homePage__ramonster-title {
                font-size: 120px;
                font-weight: bold;
                font-family: PingFangSC-Semibold;
            }

            .homePage__ramonster-info {
                margin-top: 40px;

                .homePage__ramonster-info-p {
                    margin-bottom: 50px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FAFCF9;
                    line-height: 40px;
                    flex-wrap: wrap;
                }
            }

            .homePage__ramonsterMore {
                margin-top: 60px;
                text-align: center;

                .homePage__ramonsterMore-link {
                    display: inline-block;
                    width: 180px;
                    height: 50px;
                    background: rgba(0, 155, 65, 0);
                    border: 1px solid #00FF1E;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FAFCF9;
                    line-height: 50px;
                    transition: all ease-out 1s;

                    &:hover {
                        border: 2px solid #fff;
                    }
                }
            }
        }
    }
}


@media screen and (max-width:1280px) {
    .homePage__ramonsterWrap {
        .homePage__ramonsterSlogan {
            .homePage__ramonsterSlogan-img {
                width: 781px;
                height: auto;
                z-index: -1;
            }
        }

        .homePage__ramonsterRow {
            margin-top: -40px;
            display: flex;

            .homePage__ramonsterLeft {
                margin-left: 18px;

                .homePage__ramonsterLeft-image {
                    width: 366px;
                    height: auto;
                }
            }

            .homePage__ramonsterRight {
                margin-left: 60px;
                margin-right: 10px;

                .homePage__ramonster-title {
                    font-size: 80px;
                    font-weight: bold;
                }

                .homePage__ramonster-info {
                    margin-top: 30px;

                    .homePage__ramonster-info-p {
                        margin-bottom: 40px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #FAFCF9;
                        line-height: 32px;
                        flex-wrap: wrap;
                    }
                     &.en{
                        margin-top: 20px; 
                       .homePage__ramonster-info-p {
                          margin-bottom: 30px;
                         font-size: 12px;
                         line-height: 32px;
                      }
                    }
                }

                .homePage__ramonsterMore {
                    margin-top: 30px;
                    text-align: center;

                    .homePage__ramonsterMore-link {
                        display: inline-block;
                        width: 120px;
                        height: 34px;
                        background: rgba(0, 155, 65, 0);
                        border: 1px solid #00FF1E;
                        font-size: 12px;
                        font-weight: 400;
                        color: #FAFCF9;
                        line-height: 34px;
                        transition: all ease-out 1s;
                        &:hover {
                            border: 2px solid #fff;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (min-width:1281px) and (max-width: 1746px) {
    .homePage__ramonsterWrap {
        .homePage__ramonsterSlogan {
            .homePage__ramonsterSlogan-img {
                width: 937px;
                height: auto;
                z-index: -1;
            }
        }

        .homePage__ramonsterRow {
            margin-top: -48px;
            display: flex;

            .homePage__ramonsterLeft {
                margin-left: 22px;
                .homePage__ramonsterLeft-image {
                    width: 440px;
                    height: auto;
                }
            }

            .homePage__ramonsterRight {
                margin-left: 72px;
                margin-right: 12px;

                .homePage__ramonster-title {
                    font-size: 96px;
                    font-weight: bold;
                }

                .homePage__ramonster-info {
                    margin-top: 36px;

                    .homePage__ramonster-info-p {
                        margin-bottom: 48px;
                        font-size: 15px;
                        line-height: 39px;
                    }
                    &.en{
                       margin-top: 26px; 
                       .homePage__ramonster-info-p {
                        margin-bottom: 40px;
                        font-size: 14px;
                        line-height: 32px;
                      }
                    }
                }

                .homePage__ramonsterMore {
                    margin-top: 36px;
                    text-align: center;

                    .homePage__ramonsterMore-link {
                        display: inline-block;
                        width: 144px;
                        height: 40px;
                        line-height: 40px;
                        font-size: 15px; 
                    
                    }
                }
            }
        }
    }
}