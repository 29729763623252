.qualityPage__carouselWrap {
    padding: 20px 80px;

    .qualityPage__carousel {
        position: relative;

        .qualityPage__carouselItem {
            display: flex;
            justify-content: space-between;

            .qualityPage__carouselItem-pic {
                width: 400px;
                height: auto;
            }
        }
    }
}

.carousel.carousel-slider {
    overflow: visible !important;
}


@media screen and (max-width:1280px) {

    .qualityPage__carouselWrap {
        padding: 20px 80px;

        .qualityPage__carousel {

            .qualityPage__carouselItem {
                .qualityPage__carouselItem-pic {
                    width: 260px;
                    height: auto;
                }
            }
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
     .qualityPage__carouselWrap {
        padding: 24px 96px;
        .qualityPage__carousel {
            .qualityPage__carouselItem {
                .qualityPage__carouselItem-pic {
                    width: 312px;
                    height: auto;
                }
            }
        }
    }
}