.qualityPage {
    overflow-x: hidden;
    .qualityPage__middleBg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: url(../../assets/quality/middle_pic.png) no-repeat;
        background-size: 100% 100%;
        z-index: 1;
    }

    .qualityPage__fixWrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2;

        .qualityPage__top {
            padding-top: 128px;
            background: #000;
            padding-bottom: 80px;
        }

        .qualityPage__middleEmty {
            height: 500px;
        }

        .qualityPage__foot {
            background: url(../../assets/quality/honors_bg.png) no-repeat;
            background-size: 100% auto;
            background-color: #000;
        }
    }

}




@media screen and (max-width:1920px) {
    .qualityPage {
        .qualityPage__fixWrap {
            .qualityPage__top {
                height: 100vh;
            }
        }

    }
}