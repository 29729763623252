.homePage__honorsWrap {
    .homePage__honorsTop {
        width: 100%;
        height: 234px;
        background: url(../../../../assets/home/HONORARY.png) no-repeat;
        background-size: 100% 100%;

        .homePage__honorsTitle {
            padding: 60px;
            font-size: 60px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
            line-height: 80px;
        }
    }

    .homePage__honorsDescWrap {
        text-align: right;
        padding: 50px 0;

        .homePage__honorsDesc {
            margin-right: 200px;
            text-align: left;
            display: inline-block;
            width: 420px;
            height: 49px;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 34px;
            &.en{
               margin-right: 100px;
               width: 600px; 
            }
        }
    }

    .homePage__honorsItemsWrap {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;


        .homePage__honorsItem {
            margin-bottom: 60px;
            text-align: center;
            &:not(:nth-child(1)):not(:nth-child(5)){
               margin-left: 100px;
            }

            .homePage__honorsItemCert {
                width: 284px;
                height: 400px;

                .homePage__honorsItemCert-img {
                    width: 100%;
                    height: 100%;
                }
            }

            .homePage__honorsItemDesc {
                margin-top: 15px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FAFCF9;
                line-height: 34px;
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .homePage__honorsWrap {
        .homePage__honorsTop {
            width: 100%;
            height: 156px;

            .homePage__honorsTitle {
                padding: 40px;
                font-size: 40px;
                line-height: 54px;
            }
        }

        .homePage__honorsDescWrap {
            padding: 50px 0;

            .homePage__honorsDesc {
                margin-right: 134px;
                width: 300px;
                height: 49px;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
                 &.en{
                   margin-right: 80px;
                   width: 500px;
                  font-size: 12px; 
                }
            }
        }

        .homePage__honorsItemsWrap {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .homePage__honorsItem {
                   margin-bottom: 40px;
                   text-align: center;
                   &:not(:nth-child(1)):not(:nth-child(5)){
                       margin-left: 60px;
                   }

                .homePage__honorsItemCert {

                    width: 190px;
                    height: 267px;
                    text-align: center;
                    .homePage__honorsItemCert-img{
                        width: 100%;
                        height: auto;
                    }
                }

                .homePage__honorsItemDesc {
                    width: 190px;
                    text-align: center;
                    margin-top: 10px;
                    font-size: 12px;
                    line-height: 23px;
                }
            }
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .homePage__honorsWrap {
        .homePage__honorsTop {
            width: 100%;
            height: 188px;
            .homePage__honorsTitle {
                padding: 48px;
                font-size: 48px;
                line-height: 65px;
            }
        }

        .homePage__honorsDescWrap {
            padding: 60px 0;

            .homePage__honorsDesc {
                margin-right: 160px;
                width: 360px;
                height: 60px;
                font-size: 15px;
                line-height: 26px;
                 &.en{
                   margin-right: 80px;
                   width: 600px;
                  font-size: 13px; 
                }
            }
        }

        .homePage__honorsItemsWrap {
            margin-top: 36px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;


            .homePage__honorsItem {
                   margin-bottom: 50px;
                   text-align: center;
                   &:not(:nth-child(1)):not(:nth-child(5)){
                       margin-left: 72px;
                   }
                .homePage__honorsItemCert {
                    width: 228px;
                    height: 320px;
                }

                .homePage__honorsItemDesc {
                    margin-top: 12px;
                    font-size: 15px;
                    line-height: 28px;
                }
            }
        }
    }
}