.qualityPage__honorsWrap {
    padding-bottom: 100px;

    .qualityPage__honorsTop {
        padding: 50px 0 100px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .qualityPage__honorsTitle {
            font-size: 60px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
        }

        .qualityPage__honorsDesc {
            padding-right: 60px;
            display: inline-block;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 34px;
        }

        &.en {
            display: block;
            padding: 50px 0 30px 0;

            .qualityPage__honorsDesc {
                font-size: 13px;
            }
        }
    }

    .qualityPage__honorsItemsWrap {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .qualityPage__honorsItem {
             margin-bottom: 60px;
            text-align: center;
            &:not(:nth-child(1)):not(:nth-child(5)){
               margin-left: 100px;
            }


            .qualityPage__honorsItemCert {
                width: 284px;
                height: 400px;

                .qualityPage__honorsItemCert-img {
                    width: 100%;
                    height: 100%;
                }
            }

            .qualityPage__honorsItemDesc {
                margin-top: 15px;
                font-size: 14px;
                color: #FAFCF9;
                line-height: 34px;
            }
        }
    }
}


@media screen and (max-width:1280px) {
    .qualityPage__honorsWrap {
        .qualityPage__honorsTop {
            .qualityPage__honorsTitle {
                font-size: 40px;
            }

            .qualityPage__honorsDesc {
                padding-right: 30px;
            }

            &.en {
                display: block;
                padding: 50px 0 30px 0;

                .qualityPage__honorsDesc {
                    width: 110%;
                    font-size: 12px;
                }
            }
        }

        .qualityPage__honorsItemsWrap {
            margin-top: 0px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;


            .qualityPage__honorsItem {
                margin-bottom: 40px;
                text-align: center;

                &:not(:nth-child(1)):not(:nth-child(5)) {
                    margin-left: 60px;
                }

                .qualityPage__honorsItemCert {
                    width: 190px;
                    height: 266px;

                    .qualityPage__honorsItemCert-img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .qualityPage__honorsItemDesc {
                    margin-top: 12px;
                    font-size: 12px;
                    line-height: 34px;
                }
            }
        }

    }

}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .qualityPage__honorsWrap {
        .qualityPage__honorsTop {
            .qualityPage__honorsTitle {
                font-size: 48px;
            }

            .qualityPage__honorsDesc {
                padding-right: 36px;
            }
        }

        .qualityPage__honorsItemsWrap {
            margin-top: 0px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;


            .qualityPage__honorsItem {
                margin-bottom: 50px;
                text-align: center;

                &:not(:nth-child(1)):not(:nth-child(5)) {
                    margin-left: 72px;
                }

                .qualityPage__honorsItemCert {
                    width: 228px;
                    height: 320px;

                    .qualityPage__honorsItemCert-img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .qualityPage__honorsItemDesc {
                    margin-top: 15px;
                    font-size: 15px;
                    line-height: 41px;
                }
            }
        }

    }
}