.homePage__introWrap {
    .homePage__introSlogan {
        .homePage__introSlogan-img {
            width: 1066px;
            height: auto;
            z-index: -1;
        }
    }

    .homePage__introRow {
        margin-top: -80px;
        display: flex;

        .homePage__introLeft {
            .homePage__introLeft-image {
                width: 608px;
                height: auto;
            }
        }

        .homePage__introRight {
            margin-left: 100px;
            margin-right: 15px;
            .homePage__intro-title {
                font-size: 120px;
                font-family: PingFangSC-Semibold;
            }

            .homePage__intro-info {
                margin-top: 120px;

                .homePage__intro-info-p {
                    margin-bottom: 60px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FAFCF9;
                    line-height: 40px;
                    flex-wrap: wrap;
                }
                 &.en{
                    margin-top: 80px;
                      .homePage__intro-info-p {
                        line-height: 36px;
                      }
                }
            }
        }
    }
}


@media screen and (max-width:1280px) {
    .homePage__introWrap {
        .homePage__introSlogan {
            .homePage__introSlogan-img {
                width: 710px;
                height: auto;
                z-index: -1;
            }
        }

        .homePage__introRow {
            margin-top: -80px;
            display: flex;

            .homePage__introLeft {
                .homePage__introLeft-image {
                    width: 405px;
                    height: auto;
                }
            }

            .homePage__introRight {
                margin-left: 60px;
                margin-right: 10px;

                .homePage__intro-title {
                    font-size: 80px;
                }

                .homePage__intro-info {
                    margin-top: 50px;
                    .homePage__intro-info-p {
                        margin-bottom: 40px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #FAFCF9;
                        line-height: 30px;
                        flex-wrap: wrap;
                    }
                    &.en{
                        margin-top: 40px;
                        .homePage__intro-info-p {
                             margin-bottom: 20px;
                            line-height: 32px;
                            max-height: 400px;
                            overflow-y: auto;
                            &::-webkit-scrollbar {
                               width: 5px;
                               height: 10px;
                               background-color: rgba(255,255,255,.6);
                               border-radius: 5px;
                             }
                             &::-webkit-scrollbar-thumb {
                                background-color: rgba(255,255,255,.1);
                                border-radius: 10px;
                                -webkit-box-shadow: inset1px1px0rgba(0,0,0,.1);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .homePage__introWrap {
        .homePage__introSlogan {
            .homePage__introSlogan-img {
                width: 852px;
            }
        }

        .homePage__introRow {
            margin-top: -96px;
            .homePage__introLeft {
                .homePage__introLeft-image {
                    width: 486px;
                    height: auto;
                }
            }

            .homePage__introRight {
                margin-left: 72px;
                margin-right: 12px;

                .homePage__intro-title {
                    font-size: 96px;
                }

                .homePage__intro-info {
                    margin-top: 60px;

                    .homePage__intro-info-p {
                        margin-bottom: 40px;
                        font-size: 15px;
                        line-height: 36px;
                    }
                     &.en{
                        margin-top: 40px;
                        .homePage__intro-info-p {
                             margin-bottom: 20px;
                            line-height: 32px;
                            max-height: 500px;
                            overflow-y: auto;
                            &::-webkit-scrollbar {
                               width: 5px;
                               height: 10px;
                               background-color: rgba(255,255,255,.6);
                               border-radius: 5px;
                             }
                             &::-webkit-scrollbar-thumb {
                                background-color: rgba(255,255,255,.1);
                                border-radius: 10px;
                                -webkit-box-shadow: inset1px1px0rgba(0,0,0,.1);
                            }
                        }
                    }
                }
            }
        }
    }
}