.aboutCompany {
    margin-top: 80px;
    margin-bottom: 100px;

    .aboutCompany__row {
        display: flex;
        justify-content: space-between;

        .aboutCompany__item {
            padding: 15px;
            width: 300px;
            min-height: 148px;
            background: rgba(18, 21, 23, 1);
            border: 0px solid #000000;
            cursor: pointer;

            .aboutCompany__itemName {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 44px;
            }

            .aboutCompany__address {
                margin-top: 10px;

                .aboutCompany__address-icon {
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    background: url(../../../../assets/about/icon_contact_address.png) no-repeat;
                    background-size: 100% 100%;
                    vertical-align: middle;
                }

                .aboutCompany__address-detail {
                    margin-left: 10px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    vertical-align: middle;
                }
            }

            .aboutCompany__subAddress {
                margin-top: 20px;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }

            &:hover {
                background-color: #00FF5A;

                .aboutCompany__itemName {
                    color: #000000;
                }

                .aboutCompany__address {
                    .aboutCompany__address-icon {
                        background: url(../../../../assets/about/icon_company_address.png) no-repeat;
                        background-size: 100% 100%;
                    }

                    .aboutCompany__address-detail {
                        color: #000;
                    }
                }

                .aboutCompany__subAddress {
                    color: #000;
                }

            }
        }
    }
}


@media screen and (max-width:1280px) {

    .aboutCompany {
        margin-top: 50px;
        margin-bottom: 70px;
        .aboutCompany__row {
            .aboutCompany__item {
                padding: 11px;
                width: 200px;
                min-height: 100px;
                .aboutCompany__itemName {
                    font-size: 16px;
                    line-height: 30px;
                }

                .aboutCompany__address {
                    margin-top: 6px;

                    .aboutCompany__address-icon {
                        width: 15px;
                        height: 15px;
                    }

                    .aboutCompany__address-detail {
                        margin-left: 6px;
                        font-size: 12px;
                    }
                }

                .aboutCompany__subAddress {
                    margin-top: 13px;
                    font-size: 12px;
                }
            }
        }
    }
}


@media screen and (min-width:1281px) and (max-width: 1746px) {

    .aboutCompany {
        margin-top: 60px;
        margin-bottom: 84px;
        .aboutCompany__row {
            .aboutCompany__item {
                padding: 14px;
                width: 240px;
                min-height: 120px;
                .aboutCompany__itemName {
                    font-size: 18px;
                    line-height: 36px;
                }

                .aboutCompany__address {
                    margin-top: 8px;

                    .aboutCompany__address-icon {
                        width: 18px;
                        height: 18px;
                    }

                    .aboutCompany__address-detail {
                        margin-left: 8px;
                        font-size: 14px;
                    }
                }

                .aboutCompany__subAddress {
                    margin-top: 16px;
                    font-size: 15px;
                }
            }
        }
    }
}