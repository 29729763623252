:root {
  font-family: PingFangSC-Regular, AlibabaPuHuiTi-Regular, Arial,Helvetica, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: #ffffff;
  background-color: #000000;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  margin: 0 auto;
}
a {
  font-weight: 500;
  color: #00FF5A;
  text-decoration: inherit;
}
a:hover {
  color: #00FF5A;
}

body {
  margin: 0;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #00FF5A;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.content-row{
  width: 1440px;
  margin: 0 auto;
}


@media screen and (max-width:1280px) {
  .content-row{
    width: 960px;
    margin: 0 auto;
  }

}


@media screen and (min-width:1281px) and (max-width: 1746px) {
  .content-row{
    width: 1152px;
    margin: 0 auto;
  }

}

