.homePage__productCarouselWrap {
    padding-top: 200px;
    position: relative;

    .homePage__productTitle {
        position: absolute;
        left: 0px;
        top: 0;
        z-index: 1;

        .homePage__productTitle-p {
            font-size: 60px;
            font-family: PingFangSC-Semibold;
            font-weight: 600;
            color: #FFFFFF;
            line-height: .1;
        }
    }

    .homePage__productCarousel {
        position: relative;

        .homePage__productCarouselItem {
            .homePage__productCarouselItem-pic {
                width: 1200px;
                height: 400px;
            }

            .productCarouselItem__foot {
                margin-top: -80px;
                display: flex;
                justify-content: space-between;

                .productCarouselItem__footItem {
                    position: relative;

                    .productCarouselItem__footItemPic {
                        .productCarouselItem__footItemPic-img {
                            width: 330px;
                            height: 390px;

                            &.two {
                                width: 700px;
                                height: 391px;
                            }
                        }

                    }

                    .productCarouselItem__footItem-label {
                        position: absolute;
                        left: 20px;
                        bottom: 15px;
                        font-size: 24px;
                        color: #FFFFFF;
                    }

                }
            }
        }
    }
}


@media screen and (max-width:1280px) {
    .homePage__productCarouselWrap {
        padding-top: 134px;
        position: relative;

        .homePage__productTitle {
            position: absolute;
            left: 0px;
            top: 0;
            z-index: 1;

            .homePage__productTitle-p {
                font-size: 40px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: .1;
            }
        }

        .homePage__productCarousel {
            position: relative;

            .homePage__productCarouselItem {
                .homePage__productCarouselItem-pic {
                    width: 800px;
                    height: auto;
                }

                .productCarouselItem__foot {
                    margin-top: -55px;
                    display: flex;
                    justify-content: space-between;

                    .productCarouselItem__footItem {
                        position: relative;

                        .productCarouselItem__footItemPic {
                            .productCarouselItem__footItemPic-img {
                                width: 220px;
                                height: 260px;
                                &.two {
                                    width: 466px;
                                    height: 260px;
                                }
                            }

                        }

                        .productCarouselItem__footItem-label {
                            position: absolute;
                            left: 15px;
                            bottom: 10px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #FFFFFF;
                        }

                    }
                }
            }
        }
    }
}


@media screen and (min-width:1281px) and (max-width: 1746px) {
    .homePage__productCarouselWrap {
        padding-top: 160px;
        position: relative;

        .homePage__productTitle {
            position: absolute;
            left: 0px;
            top: 0;
            z-index: 1;

            .homePage__productTitle-p {
                font-size: 48px;
                line-height: .1;
            }
        }

        .homePage__productCarousel {
            position: relative;

            .homePage__productCarouselItem {
                .homePage__productCarouselItem-pic {
                    width: 960px;
                    height: auto;
                }

                .productCarouselItem__foot {
                    margin-top: -70px;
                    display: flex;
                    justify-content: space-between;
                    .productCarouselItem__footItem {
                        position: relative;

                        .productCarouselItem__footItemPic {
                            .productCarouselItem__footItemPic-img {
                                width: 264px;
                                height: 321px;
                                &.two {
                                    width: 560px;
                                    height: 321px;
                                }
                            }

                        }

                        .productCarouselItem__footItem-label {
                            position: absolute;
                            left: 18px;
                            bottom: 12px;
                            font-size: 20px;
                        }

                    }
                }
            }
        }
    }
}
