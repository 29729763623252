.productLPDDR {
    padding-top: 120px;
    padding-bottom: 80px;
    background: url(../../../../assets/product/DDR_bg.png) no-repeat;
    background-size: cover;

    .productLPDDR__top {
        .productLPDDR__topTitle {
            font-size: 80px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
        }

        .productLPDDR__topDesc {
            margin-top: 30px;
            width: 573px;
            font-size: 14px;
            color: #FFFFFF;
        }

        &.en {
            .productDDR__topTitle {
                font-size: 60px;
            }

            .productDDR__topDesc {
                font-size: 13px;
            }
        }
    }

    .productLPDDR__middle {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .productLPDDR__middleLeft {
            .productLPDDR__iconsRow {
                width: 400px;
                display: flex;
                justify-content: space-around;

                .productLPDDR__icon {
                    text-align: center;

                    .productLPDDR__icon-pic {
                        width: 50px;
                        height: 50px;
                    }

                    .productLPDDR__icon-label {
                        font-size: 14px;
                        color: #FFFFFF;
                         &.en {
                            font-size: 13px;
                            transform: scale(0.9);
                        }
                    }
                }
            }
        }

        .productLPDDR__middleRight {
            .productLPDDR__middleRight-pic {
                margin-right: 50px;
                width: 480px;
                height: auto;
            }
        }
    }

    .productLPDDR__foot {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;

        .productLPDDR__footItem {
            .productLPDDR__footItemPic {
                .productLPDDR__footItemPic-img {
                    width: 700px;
                    height: auto;
                }
            }

            .productLPDDR__footItem-label {
                margin-top: -10px;
                width: 690px;
                padding-left: 10px;
                height: 60px;
                line-height: 60px;
                background: #12161B;
                border: 0px solid #000000;
                font-size: 24px;
                color: #FFFFFF;
            }
        }
    }
}


@media screen and (max-width:1280px) {
    .productLPDDR {
        padding-top: 80px;
        padding-bottom: 50px;

        .productLPDDR__top {
            .productLPDDR__topTitle {
                font-size: 54px;
            }

            .productLPDDR__topDesc {
                margin-top: 20px;
                width: 420px;
                font-size: 12px;
            }

            &.en {
                .productDDR__topTitle {
                    font-size: 50px;
                }

                .productDDR__topDesc {
                    width: 450px;
                }
            }
        }

        .productLPDDR__middle {
            margin-top: 20px;

            .productLPDDR__middleLeft {
                .productLPDDR__iconsRow {
                    width: 266px;

                    .productLPDDR__icon {
                        .productLPDDR__icon-pic {
                            width: 34px;
                            height: 34px;
                        }

                        .productLPDDR__icon-label {
                            font-size: 12px;
                            transform: scale(0.8);
                            
                        }
                    }
                }
            }

            .productLPDDR__middleRight {
                .productLPDDR__middleRight-pic {
                    margin-right: 35px;
                    width: 320px;
                    height: auto;
                }
            }
        }

        .productLPDDR__foot {
            margin-top: 54px;

            .productLPDDR__footItem {
                .productLPDDR__footItemPic {
                    .productLPDDR__footItemPic-img {
                        width: 466px;
                        height: auto;
                    }
                }

                .productLPDDR__footItem-label {
                    margin-top: -6px;
                    width: 456px;
                    padding-left: 10px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .productLPDDR {
        padding-top: 96px;
        padding-bottom: 60px;

        .productLPDDR__top {
            .productLPDDR__topTitle {
                font-size: 65px;
            }

            .productLPDDR__topDesc {
                margin-top: 24px;
                width: 504px;
                font-size: 15px;
            }

            &.en {
                .productDDR__topTitle {
                    font-size: 60px;
                }

                .productDDR__topDesc {
                    width: 550px;
                }
            }
        }

        .productLPDDR__middle {
            margin-top: 24px;

            .productLPDDR__middleLeft {
                .productLPDDR__iconsRow {
                    width: 320px;

                    .productLPDDR__icon {
                        .productLPDDR__icon-pic {
                            width: 41px;
                            height: 41px;
                        }

                        .productLPDDR__icon-label {
                            font-size: 15px;
                            transform: scale(0.9);
                            &.en {
                                font-size: 12px;
                               
                            }
                        }
                    }
                }
            }

            .productLPDDR__middleRight {
                .productLPDDR__middleRight-pic {
                    margin-right: 42px;
                    width: 384px;
                    height: auto;
                }
            }
        }

        .productLPDDR__foot {
            margin-top: 65px;

            .productLPDDR__footItem {
                .productLPDDR__footItemPic {
                    .productLPDDR__footItemPic-img {
                        width: 560px;
                        height: auto;
                    }
                }

                .productLPDDR__footItem-label {
                    margin-top: -8px;
                    width: 547px;
                    padding-left: 12px;
                    height: 48px;
                    line-height: 48px;
                    font-size: 20px;
                }
            }
        }
    }
}