.aboutCulture {
    margin-top: 20px;

    .aboutCulture__top {
        position: relative;

        .aboutCulture__topImg {
            width: 100%;
            height: auto;
        }

        .aboutCulture__title {
            width: 100%;
            height: 60px;
            top: 50%;
            margin-top: -30px;
            text-align: center;
            position: absolute;
            font-size: 60px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
        }
    }

    .aboutCulture__contentRow {
        margin-top: -140px;
        display: flex;

        .aboutCulture__col {
            flex: 1;
            position: relative;
            text-align: center;
            cursor: pointer;

            .aboutCulture__colImg {
                width: 100%;
                height: auto;
            }

            .aboutCulture__content {
                position: absolute;
                width: 100%;
                top: 25%;

                .aboutCulture__subtitle {
                    font-size: 60px;
                    font-family: PingFangSC-Semibold;
                    font-weight: 600;
                    color: #FFFFFF;
                }

                .aboutCulture__desc {
                    margin-top: 20px;
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }

            &:hover {
                .aboutCulture__content {
                    .aboutCulture__subtitle {
                        color: #00FF5A;
                    }

                    .aboutCulture__desc {
                        color: #00FF5A;
                    }
                }
            }
        }
    }

    &.en {
        .aboutCulture__contentRow {
            .aboutCulture__col {
                .aboutCulture__content {
                    .aboutCulture__subtitle {
                        font-size: 45px;
                    }

                    .aboutCulture__desc {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .aboutCulture {
        margin-top: 15px;

        .aboutCulture__top {
            .aboutCulture__title {
                width: 100%;
                height: 40px;
                top: 50%;
                margin-top: -20px;
                font-size: 40px;
            }
        }

        .aboutCulture__contentRow {
            margin-top: -100px;

            .aboutCulture__col {
                .aboutCulture__content {
                    .aboutCulture__subtitle {
                        font-size: 40px;
                    }

                    .aboutCulture__desc {
                        padding: 0 5px;
                        margin-top: 14px;
                        font-size: 12px;
                    }
                }
            }
        }

        &.en {
            .aboutCulture__contentRow {
                .aboutCulture__col {
                    .aboutCulture__content {
                        .aboutCulture__subtitle {
                            font-size: 30px;
                        }

                        .aboutCulture__desc {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }


}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .aboutCulture {
        margin-top: 18px;

        .aboutCulture__top {
            .aboutCulture__title {
                width: 100%;
                height: 48px;
                top: 50%;
                margin-top: -24px;
                font-size: 48px;
            }
        }

        .aboutCulture__contentRow {
            margin-top: -120px;

            .aboutCulture__col {
                .aboutCulture__content {
                    .aboutCulture__subtitle {
                        font-size: 48px;
                    }

                    .aboutCulture__desc {
                        padding: 0 6px;
                        margin-top: 18px;
                        font-size: 15px;
                    }
                }
            }
        }

        &.en {
            .aboutCulture__contentRow {
                .aboutCulture__col {
                    .aboutCulture__content {
                        .aboutCulture__subtitle {
                            font-size: 36px;
                        }

                        .aboutCulture__desc {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

}