.terabyteContainer {
    text-align: center;

    .terabyteContainer__img {
        width: 1179px;
        height: auto;
    }
}

@media screen and (max-width:1280px) {
    .terabyteContainer {
        .terabyteContainer__img {
            width: 786px;
            height: auto;
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .terabyteContainer {
        .terabyteContainer__img {
            width: 943px;
            height: auto;
        }
    }
}