.homePage__developCarouselWrap {
    position: relative;

    .developCarousel__row {
        width: 84%;
        margin: 0 auto;

        .developCarousel__bg {
            width: 100%;
            height: auto;
            z-index: 0;
        }

        .homePage__developTitle {
            position: absolute;
            left: 15%;
            top: -9%;
            z-index: 3;
            .homePage__developTitle-p {
                font-size: 60px;
                color: #FFFFFF;
                line-height: .2;
                font-family: PingFangSC-Semibold;
            }
        }

        .developCarousel__left {
            width: 55%;
            position: absolute;
            left: 0;
            top: 10%;
            z-index: 1;

            .homePage__developCarousel {
                position: relative;

                .homePage__developCarouselItem {
                    .homePage__developCarouselItem-pic {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .developCarousel__leftEmpty {
                height: 80px;
            }
        }
    }

    .developCarousel__right {
        position: absolute;
        left: 58%;
        top: 0;
        z-index: 1;
        width: 30%;
        min-height: 900px;

        .developCarousel__rightTitle {
            margin-top: 38%;
            padding-bottom: 50px;
            p {
                font-size: 40px;
                color: #00FF5A;
                font-family: PingFangSC-Semibold;
                line-height: .5;
            }
             &.en{
                 margin-top: 30%;
                 padding-bottom: 0px;
                    p{
                      font-size: 38px; 
                      line-height: 1.2;
                    }
            }
        }

        .developCarousel__rightDesc {
            padding-top: 80px;
            text-align: left;
            font-size: 14px;
            color: #00FF5A;
            line-height: 2;
        }

        .developCarousel__readMore {
            margin-top: 40px;
            text-align: left;

            .developCarousel__readMore-link {
                display: inline-block;
                width: 144px;
                height: 40px;
                line-height: 40px;
                font-size: 15px;
                border: 1px solid #00FF1E;
                color: #fff;
                text-align: center;
                cursor: pointer;
                &:hover {
                    color: #00FF1E;
                }
            }
        }
    }

}


@media screen and (max-width:1280px) {
    .homePage__developCarouselWrap {
        .developCarousel__row {
            .homePage__developTitle {
                .homePage__developTitle-p {
                    font-size: 40px;
                    line-height: .2;
                }
            }
        }

        .developCarousel__right {
            min-height: 600px;
            width: 32%;
            .developCarousel__rightTitle {
                margin-top: 36%;
                p {
                    font-size: 26px;
                }
                &.en{
                    p{
                      font-size: 20px;  
                    }
                }
            }

            .developCarousel__rightDesc {
                 padding-top: 40px;
                font-size: 12px;
            }

            .developCarousel__readMore {
                margin-top: 24px;

                .developCarousel__readMore-link {
                    display: inline-block;
                    width: 96px;
                    height: 26px;
                    line-height: 26px;
                    font-size: 12px;

                }
            }
        }

    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .homePage__developCarouselWrap {
        .developCarousel__row {
            .homePage__developTitle {
                .homePage__developTitle-p {
                    font-size: 48px;
                    line-height: .2;
                }
            }
        }

        .developCarousel__right {
            min-height: 720px;
            width: 32%;
            .developCarousel__rightTitle {
                margin-top: 36%;
                p {
                    font-size: 32px;
                }
                 &.en{
                    p{
                      font-size: 24px;  
                     }
                 }
            }

            .developCarousel__rightDesc {
                padding-top: 50px;
                font-size: 15px;
            }

            .developCarousel__readMore {
                margin-top: 32px;

                .developCarousel__readMore-link {
                    display: inline-block;
                    width: 115px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 15px;

                }
            }
        }

    }
}