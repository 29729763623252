.aboutContact {
    padding-top: 100px;

    .aboutContact__map {
        position: relative;

        .aboutContact__title {
            font-size: 60px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
            text-align: center;
        }

        .aboutContact__mapImg {
            position: relative;
            margin-top: -50px;
            width: 100%;
            height: auto;
            z-index: -1;
        }

        .lightsWrap {
            height: 100%;

            .light__iconWhite {
                position: absolute;
                width: 52px;
                height: 52px;
                background: url(../../../../assets/about/light_white.png) no-repeat;
                background-size: cover;
                opacity: .6;
                transition: all 1s ease-in-out;

                &.light {
                    opacity: 1;
                }
            }

            .light__iconGolden {
                position: absolute;
                width: 52px;
                height: 52px;
                background: url(../../../../assets/about/light_golden.png) no-repeat;
                background-size: cover;
                opacity: .5;
                transition: all 1.5s ease-in-out;

                &.light {
                    opacity: 1;
                }
            }

            .text__pos {
                position: absolute;
                font-size: 12px;
                color: #fff;

            }
        }
    }

    .aboutContact__company {
        width: 320px;
        margin: -80px auto 0 auto;
        text-align: left;

        .aboutContact__companyName {
            font-size: 26px;
            font-family: PingFangSC-Semibold;
            color: #00FF5A;
        }

        .aboutContact__address {
            margin-top: 15px;
            display: flex;

            .aboutContact__address-icon {
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url(../../../../assets/about/icon_contact_address.png) no-repeat;
                background-size: 100% 100%;
            }

            .aboutContact__addressRight {
                flex: 1;
                margin-left: 10px;

                .aboutContact__address-detail {
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }
        }

        .aboutContact__email {
            margin-top: 15px;
            display: flex;

            .aboutContact__email-icon {
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url(../../../../assets/about/icon_contact_email.png) no-repeat;
                background-size: 100% 100%;
            }

            .aaboutContact__emailDetail {
                margin-left: 10px;
                font-size: 14px;
                color: #FFFFFF;
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .aboutContact {
        margin-top: 100px;

        .aboutContact__map {
            .aboutContact__title {
                font-size: 40px;
            }

            .aboutContact__mapImg {
                margin-top: -34px;
            }
        }

        .aboutContact__company {
            width: 245px;
            margin: -45px auto 0 auto;

            .aboutContact__companyName {
                font-size: 17px;
            }

            .aboutContact__address {
                margin-top: 15px;

                .aboutContact__address-icon {
                    width: 15px;
                    height: 15px;
                }

                .aboutContact__addressRight {
                    margin-left: 6px;

                    .aboutContact__address-detail {
                        font-size: 12px;
                    }
                }
            }

            .aboutContact__email {
                margin-top: 11px;

                .aboutContact__email-icon {
                    width: 15px;
                    height: 15px;
                }

                .aaboutContact__emailDetail {
                    margin-left: 6px;
                    font-size: 12px;
                }
            }

            &.en {
                width: 260px;

                .aboutContact__companyName {
                    font-size: 12px;
                }

                .aboutContact__address {
                    margin-top: 15px;
                    width: 105%;

                    .aboutContact__address-icon {
                        width: 15px;
                        height: 15px;
                    }

                    .aboutContact__addressRight {
                        margin-left: 6px;

                        .aboutContact__address-detail {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .aboutContact {
        margin-top: 120px;

        .aboutContact__map {
            .aboutContact__title {
                font-size: 48px;
            }

            .aboutContact__mapImg {
                margin-top: -40px;
            }
        }

        .aboutContact__company {
            width: 294px;
            margin: -53px auto 0 auto;

            .aboutContact__companyName {
                font-size: 20px;
            }

            .aboutContact__address {
                margin-top: 18px;

                .aboutContact__address-icon {
                    width: 18px;
                    height: 18px;
                }

                .aboutContact__addressRight {
                    margin-left: 7px;

                    .aboutContact__address-detail {
                        font-size: 15px;
                    }
                }
            }

            .aboutContact__email {
                margin-top: 14px;

                .aboutContact__email-icon {
                    width: 18px;
                    height: 18px;
                }

                .aaboutContact__emailDetail {
                    margin-left: 7px;
                    font-size: 15px;
                }
            }

            &.en {
                .aboutContact__companyName {
                    font-size: 14px;
                }

                .aboutContact__address {
                    margin-top: 18px;

                    .aboutContact__address-icon {
                        width: 15px;
                        height: 15px;
                    }

                    .aboutContact__addressRight {
                        margin-left: 7px;

                        .aboutContact__address-detail {
                            font-size: 13px;
                        }
                    }
                }

                .aboutContact__email {
                    margin-top: 14px;

                    .aboutContact__email-icon {
                        width: 18px;
                        height: 18px;
                    }

                    .aaboutContact__emailDetail {
                        margin-left: 7px;
                        font-size: 13px;
                    }
                }

            }

        }

    }
}