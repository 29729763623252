.homePage__cooperWrap {
    .cooper__title {
        padding: 60px 20px 20px 20px;
        text-align: center;
        font-size: 60px;
        font-family: PingFangSC-Semibold;
    }

    .cooper__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;

        .cooper__item {
            .cooper__itemPic {
                width: 216px;
                height: auto;
            }
        }
    }
}


@media screen and (max-width:1280px) {
    .homePage__cooperWrap {
        .cooper__title {
             padding: 80px 15px 15px 15px;
            font-size: 40px;
        }

        .cooper__list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;

            .cooper__item {
                .cooper__itemPic {
                    width: 144px;
                    height: auto;
                }
            }
        }
    }

}

@media screen and (min-width:1281px) and (max-width: 1746px) {
     .homePage__cooperWrap {
        .cooper__title {
             padding: 70px 18px 18px 18px;
            font-size: 48px;
        }

        .cooper__list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 12px;

            .cooper__item {
                .cooper__itemPic {
                    width: 173px;
                    height: auto;
                }
            }
        }
    }
}