
.productPage{
    padding-top: 70px;
    padding-bottom: 120px;
    .productPage__foot{
    	padding: 20px;
    	text-align: center;
    	font-size: 16px;
    }
}

@media screen and (max-width:1280px) {
	.productPage{
    	padding-top: 60px;
   		padding-bottom: 80px;
     .productPage__foot{
    	padding: 13px;
    	font-size: 12px;
    }
  }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
	.productPage{
    	padding-top: 70px;
   		padding-bottom: 100px;
     .productPage__foot{
    	padding: 16px;
    	font-size: 13px;
    }
 }
}