.headerContainer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    opacity: 1;
    transition: all .4s ease-in;
    -moz-transition: all .4s ease-in;
    -webkit-transition: all .4s ease-in;

    &.out {
        opacity: 0;
    }

    .headerContainer__row {
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        height: 70px;
        overflow-y: hidden;
        transition: all linear 1s;
        cursor: pointer;

        &:hover {
            height: auto;
            overflow-y: auto;
        }

        .headerContainer__logo {
            padding-top: 20px;

            .headerContainer__logo-image {
                width: 184px;
                height: 28px;
            }

        }

        .headerContainer__language {
            padding-top: 20px;
            font-size: 14px;
            color: #888888;

            .headerContainer__language-link {
                color: #888888;
                cursor: pointer;

                &.active {
                    color: #00FF5A;
                }
            }

            .headerContainer__language-ico {
                margin-right: 10px;
                vertical-align: middle;
                width: 30px;
                height: 30px;
            }
        }

        .headerContainer__menu {
            width: 60%;
            display: flex;
            justify-content: space-between;

            .headerContainer__menuCol {
                padding: 20px 30px;
                height: auto;

                &:hover:not(:first-child) {
                    background: #00FF5A;

                    .headerContainer__menu-title {
                        color: #000000;
                    }

                    .headerContainer__items {

                        .headerContainer__menu-itemLink {
                            color: #000000;
                        }
                    }
                }

                &:hover:first-child {
                    padding: 5px 0px;

                    .headerContainer__menu-title {
                        line-height: 50px;
                        padding: 20px 30px;
                        color: #000000;
                        background: #00FF5A;
                    }
                }

                &.cur {
                    background: #00FF5A;

                    &:hover {
                        background: none;

                        .headerContainer__menu-title {
                            color: #00FF5A;
                        }
                    }

                    .headerContainer__menu-title {
                        color: #000000;
                    }

                    .headerContainer__items {

                        .headerContainer__menu-itemLink {
                            color: #000000;
                        }
                    }
                }

                .headerContainer__menu-title {
                    font-size: 20px;
                    font-family: PingFangSC-Semibold;
                }

                .headerContainer__items {
                    margin-top: 15px;

                    .headerContainer__menu-itemLink {
                        display: block;
                        margin-right: 15px;
                        font-size: 13px;
                        line-height: 26px;
                    }
                }
            }

            &.en {
                .headerContainer__menuCol {
                    padding: 20px 40px;

                    .headerContainer__menu-title {
                        font-size: 18px;
                    }

                    .headerContainer__items {
                        .headerContainer__menu-itemLink {
                            font-size: 12px;
                        }
                    }
                }

            }
        }
    }
}

@media screen and (max-width:1280px) {
    .headerContainer {
        .headerContainer__row {
            padding: 0 30px;
            height: 60px;
            display: flex;
            justify-content: space-between;

            .headerContainer__logo {
                .headerContainer__logo-image {
                    width: 122px;
                    height: auto;
                }

            }

            .headerContainer__language {
                font-size: 12px;

                .headerContainer__language-ico {
                    margin-right: 10px;
                    vertical-align: middle;
                    width: 20px;
                    height: 20px;
                }
            }

            .headerContainer__menu {
                width: 60%;
                display: flex;
                justify-content: space-around;

                .headerContainer__menuCol {
                    .headerContainer__menu-title {
                        font-size: 14px;
                    }

                    .headerContainer__items {
                        margin-top: 10px;

                        .headerContainer__menu-itemLink {
                            margin-right: 10px;
                            font-size: 12px;
                        }
                    }
                }

                &.en {
                    .headerContainer__menuCol {
                        padding: 20px 40px;
                        .headerContainer__menu-title {
                            font-size: 12px;
                        }

                        .headerContainer__items {
                            .headerContainer__menu-itemLink {
                                font-size: 12px;
                            }
                        }
                    }

                }
            }
        }
    }
}