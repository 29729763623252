.aboutIntroduce {
    padding-top: 60px;

    .aboutIntroduce__row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .aboutIntroduce__left {
            width: 720px;

            .aboutIntroduce__title {
                font-size: 60px;
                font-family: PingFangSC-Semibold;
                color: #00FF5A;
                line-height: 15px;
            }

            .aboutIntroduce__info {
                margin-top: 35px;

                .aboutIntroduce__info-p {
                    margin-bottom: 60px;
                    font-size: 14px;
                    color: #FAFCF9;
                    line-height: 44px;
                }

                &.en {
                    .aboutIntroduce__info-p {
                        line-height: 36px;
                        font-size: 14px;
                        max-height: 360px;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 5px;
                            height: 10px;
                            background-color: rgba(255, 255, 255, .6);
                            border-radius: 5px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: rgba(255, 255, 255, .1);
                            border-radius: 10px;
                            -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, .1);
                        }
                    }
                }
            }
        }

        .aboutIntroduce__right {
            .aboutIntroduce__items {
                margin-top: -10px;
                display: flex;
                justify-content: space-between;
                width: 600px;
                flex-wrap: wrap;

                .aboutIntroduce__itemBg {
                    width: 290px;
                    height: 200px;

                    .aboutIntroduce__itemLabel {
                        padding: 0 15px;
                        text-align: right;
                        font-size: 40px;
                        font-family: PingFang SC;
                        color: #FFFFFF;
                    }

                    &.design {
                        background: url(../../../../assets/about/introduce_design.png) no-repeat;
                        background-size: 100% 100%;
                    }

                    &.develop {
                        background: url(../../../../assets/about/introduce_develop.png) no-repeat;
                        background-size: 100% 100%;
                    }

                    &.produces {
                        background: url(../../../../assets/about/introduce_produces.png) no-repeat;
                        background-size: 100% 100%;
                    }

                    &.sale {
                        background: url(../../../../assets/about/introduce_sale.png) no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .aboutIntroduce {
        .aboutIntroduce__row {
            .aboutIntroduce__left {
                width: 480px;

                .aboutIntroduce__title {
                    font-size: 40px;
                    line-height: 15px;
                }

                .aboutIntroduce__info {
                    margin-top: 26px;

                    .aboutIntroduce__info-p {
                        font-size: 12px;
                        line-height: 24px;
                    }

                    &.en {
                        .aboutIntroduce__info-p {
                            line-height: 24px;
                            font-size: 12px;
                            max-height: 240px;
                            overflow-y: auto;

                            &::-webkit-scrollbar {
                                width: 5px;
                                height: 10px;
                                background-color: rgba(255, 255, 255, .6);
                                border-radius: 5px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: rgba(255, 255, 255, .1);
                                border-radius: 10px;
                                -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, .1);
                            }
                        }
                    }
                }
            }

            .aboutIntroduce__right {
                .aboutIntroduce__items {
                    width: 400px;

                    .aboutIntroduce__itemBg {
                        width: 193px;
                        height: 133px;

                        .aboutIntroduce__itemLabel {
                            padding: 0 10px;
                            font-size: 26px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .aboutIntroduce {
        .aboutIntroduce__row {
            .aboutIntroduce__left {
                width: 576px;

                .aboutIntroduce__title {
                    font-size: 48px;
                    line-height: 18px;
                }

                .aboutIntroduce__info {
                    margin-top: 30px;

                    .aboutIntroduce__info-p {
                        font-size: 13px;
                        line-height: 32px;
                    }

                    &.en {
                        .aboutIntroduce__info-p {
                            line-height: 24px;
                            font-size: 12px;
                            max-height: 280px;
                            overflow-y: auto;

                            &::-webkit-scrollbar {
                                width: 5px;
                                height: 10px;
                                background-color: rgba(255, 255, 255, .6);
                                border-radius: 5px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: rgba(255, 255, 255, .1);
                                border-radius: 10px;
                                -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, .1);
                            }
                        }
                    }
                }
            }

            .aboutIntroduce__right {
                .aboutIntroduce__items {
                    width: 480px;

                    .aboutIntroduce__itemBg {
                        width: 232px;
                        height: 160px;

                        .aboutIntroduce__itemLabel {
                            padding: 0 12px;
                            font-size: 31px;
                        }
                    }
                }
            }
        }
    }
}