.homePage {
    overflow-x: hidden;

    .homePage__videoWrap {
        width: 100%;
        height: 100vh;
    }

    .homePage__intro {
        margin-top: 100px;
    }

    .homePage__ramonster {
        margin-top: 150px;
    }

    .homePage__product {
        margin-top: 150px;
    }

    .homePage__devlop {
        margin-top: 100px;
        margin-bottom: 120px;
    }

    .homePage__cooper {
        margin-bottom: 150px;
    }

    .homePage__readMore {
        margin-top: 60px;
        text-align: center;

        .homePage__readMore-link {
            display: inline-block;
            width: 180px;
            height: 50px;
            background: rgba(0, 155, 65, 0);
            border: 1px solid #00FF1E;
            font-size: 14px;
            font-weight: 400;
            color: #FAFCF9;
            line-height: 50px;
            transition: all ease-out 1s;

            &:hover {
                border: 2px solid #fff;
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .homePage {
        .homePage__readMore {
            margin-top: 40px;
            text-align: center;

            .homePage__readMore-link {
                width: 120px;
                height: 34px;
                font-size: 12px;
                line-height: 34px;

            }
        }
    }
}


@media screen and (min-width:1281px) and (max-width: 1746px) {
     .homePage {
        .homePage__readMore {
            margin-top: 48px;
            text-align: center;
            .homePage__readMore-link {
                width: 124px;
                height: 40px;
                line-height: 40px;
                font-size: 15px;

            }
        }
    }

}