.footerContainer {
    background-color: rgba(0, 0, 0, 1);
    border-top: 1px solid #00FF5A;
    .footerContainer__row {
        padding: 40px 30px;
        display: flex;
        justify-content: space-between;

        .footerContainer__logo {
            .footerContainer__logo-image {
                width: 184px;
                height: 28px;
            }

        }

        .footerContainer__language {
            font-size: 14px;
            color: #888888;

            .footerContainer__language-link {
                color: #888888;
                cursor: pointer;

                &.active {
                    color: #00FF5A;
                }
            }

            .footerContainer__language-ico {
                margin-right: 10px;
                vertical-align: middle;
                width: 30px;
                height: 30px;
            }
        }

        .footerContainer__menu {
            width: 60%;
             display: flex;
            justify-content: space-around;
            .footerContainer__menuCol {
                padding: 0px 30px;
                .footerContainer__menu-title {
                    font-size: 20px;
                    font-family: PingFangSC-Semibold; 
                }

                .footerContainer__items {
                    margin-top: 15px;

                    .footerContainer__menu-itemLink {
                        display: block;
                        margin-top: 20px;
                        font-size: 13px;
                    }
                }
            }
        }

    
    }

    .footerContainer__copyright {
        padding: 20px;
        text-align: center;
        font-size: 14px;
        color: #6D6D6D;
        a{
            color: #6D6D6D;
        }
    }
}


@media screen and (max-width:1280px) {
    .footerContainer {
        .footerContainer__row {
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            .footerContainer__logo {
                .footerContainer__logo-image {
                    width: 122px;
                    height: auto;
                }

            }

            .footerContainer__language {
                font-size: 12px;
                .footerContainer__language-ico {
                    margin-right: 10px;
                    vertical-align: middle;
                    width: 20px;
                    height: 20px;
                }
            }

            .footerContainer__menu {

                .footerContainer__menuCol {

                    .footerContainer__menu-title {
                        font-size: 15px;
                    }

                    .footerContainer__items {
                        margin-top: 15px;

                        .footerContainer__menu-itemLink {
                            display: block;
                            margin-top: 14px;
                            font-size: 12px;
                        }
                    }
                }
            }

            .footerContainer__navigator {
                width: 35%;

                .footerContainer__navigatorContent {
                    margin-top: 175px;

                    .footerContainer__navigator-itemLink {
                        margin: 0 4px;
                        font-size: 12px;
                    }
                }
            }
        }

        .footerContainer__copyright {
            padding: 20px;
            text-align: center;
            font-size: 12px;
            color: #6D6D6D;
        }
    }
}