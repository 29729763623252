.productDDR {
    padding-top: 120px;
    padding-bottom: 80px;
    background: url(../../../../assets/product/DDR_bg.png) no-repeat;
    background-size: cover;

    .productDDR__top {
        .productDDR__topTitle {
            font-size: 80px;
            font-family: PingFangSC-Semibold;
            color: #FFFFFF;
        }

        .productDDR__topDesc {
            margin-top: 30px;
            width: 640px;
            font-size: 14px;
            color: #FFFFFF;
        }

        &.en {
            .productDDR__topTitle {
                font-size: 60px;
            }

            .productDDR__topDesc {
                font-size: 13px;
            }
        }
    }

    .productDDR__middle {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .productDDR__middleLeft {
            .productDDR__iconsRow {
                width: 400px;
                display: flex;
                justify-content: space-around;

                .productDDR__icon {
                    text-align: center;

                    .productDDR__icon-pic {
                        width: 50px;
                        height: 50px;
                    }

                    .productDDR__icon-label {
                        font-size: 14px;
                        color: #FFFFFF;
                        &.en {
                            font-size: 13px;
                            transform: scale(0.9);
                        }
                    }
                }
            }
        }

        .productDDR__middleRight {
            .productDDR__middleRight-pic {
                margin-right: 50px;
                width: 526px;
                height: auto;
            }
        }
    }

    .productDDR__foot {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;

        .productDDR__footItem {
            .productDDR__footItemPic {
                .productDDR__footItemPic-img {
                    width: 330px;
                    height: 360px;
                }
            }

            .productDDR__footItem-label {
                margin-top: -10px;
                width: 320px;
                padding-left: 10px;
                height: 60px;
                line-height: 60px;
                background: #12161B;
                border: 0px solid #000000;
                font-size: 24px;
                color: #FFFFFF;
            }
        }
    }
}


@media screen and (max-width:1280px) {
    .productDDR {
        padding-top: 80px;
        padding-bottom: 50px;

        .productDDR__top {
            .productDDR__topTitle {
                font-size: 54px;
            }

            .productDDR__topDesc {
                margin-top: 20px;
                width: 420px;
                font-size: 12px;
            }

            &.en {
                .productDDR__topTitle {
                    font-size: 50px;
                }

                .productDDR__topDesc {
                    width: 450px;
                }
            }
        }

        .productDDR__middle {
            margin-top: 20px;

            .productDDR__middleLeft {
                .productDDR__iconsRow {
                    width: 266px;

                    .productDDR__icon {

                        .productDDR__icon-pic {
                            width: 34px;
                            height: 34px;
                        }

                        .productDDR__icon-label {
                            font-size: 12px;
                            transform: scale(0.8);
                        }
                    }
                }
            }

            .productDDR__middleRight {
                .productDDR__middleRight-pic {
                    margin-right: 35px;
                    width: 350px;
                    height: auto;
                }
            }
        }

        .productDDR__foot {
            margin-top: 54px;

            .productDDR__footItem {
                .productDDR__footItemPic {
                    .productDDR__footItemPic-img {
                        width: 220px;
                        height: auto;
                    }
                }

                .productDDR__footItem-label {
                    margin-top: -6px;
                    width: 210px;
                    padding-left: 10px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 16px;
                }
            }
        }
    }
}



@media screen and (min-width:1281px) and (max-width: 1746px) {
    .productDDR {
        padding-top: 96px;
        padding-bottom: 60px;

        .productDDR__top {
            .productDDR__topTitle {
                font-size: 65px;
            }

            .productDDR__topDesc {
                margin-top: 24px;
                width: 504px;
                font-size: 15px;
            }

            &.en {
                .productDDR__topTitle {
                    font-size: 60px;
                }

                .productDDR__topDesc {
                    width: 550px;
                }
            }
        }

        .productDDR__middle {
            margin-top: 24px;

            .productDDR__middleLeft {
                .productDDR__iconsRow {
                    width: 320px;

                    .productDDR__icon {

                        .productDDR__icon-pic {
                            width: 41px;
                            height: 41px;
                        }

                        .productDDR__icon-label {
                            font-size: 15px;
                        }
                    }
                }
            }

            .productDDR__middleRight {
                .productDDR__middleRight-pic {
                    margin-right: 42px;
                    width: 420px;
                    height: auto;
                }
            }
        }

        .productDDR__foot {
            margin-top: 65px;

            .productDDR__footItem {
                .productDDR__footItemPic {
                    .productDDR__footItemPic-img {
                        width: 264px;
                        height: auto;
                    }
                }

                .productDDR__footItem-label {
                    margin-top: -8px;
                    width: 252px;
                    padding-left: 12px;
                    height: 48px;
                    line-height: 48px;
                    font-size: 20px;
                }
            }
        }
    }
}