.productBanner {
    position: relative;

    .productBanner__pic {
        width: 100%;
        height: auto;
    }

    .productBanner__title {
        position: absolute;
        width: 1440px;
        left: 50%;
        margin-left: -720px;
        top: 30%;

        .productBanner__title-data {
            font-size: 80px;
            color: #00FF5A;
            font-family: PingFangSC-Semibold;
        }

        &.en {
            .productBanner__title-data {
                font-size: 60px;
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .productBanner {
        .productBanner__title {
            width: 960px;
            left: 50%;
            margin-left: -480px;
            top: 30%;

            .productBanner__title-data {
                font-size: 54px;
            }

            &.en {
                .productBanner__title-data {
                    font-size: 45px;
                }
            }
        }
    }

}

@media screen and (min-width:1281px) and (max-width: 1746px) {
    .productBanner {
        .productBanner__title {
            width: 1152px;
            left: 50%;
            margin-left: -576px;
            top: 30%;

            .productBanner__title-data {
                font-size: 65px;
            }

            &.en {
                .productBanner__title-data {
                    font-size: 50px;
                }
            }
        }
    }

}