.aboutHistory {
    margin-top: 60px;
    text-align: center;

    .aboutHistory__title {
        font-size: 60px;
        font-family: PingFangSC-Semibold;
        color: #FFFFFF;
    }

    .aboutHistory__list {
        margin-top: 100px;
        text-align: left;

        .aboutHistory__item {
            position: relative;
            margin-top: -8px;

            .aboutHistory__itemImg {
                width: 1080px;
                height: auto;
            }

            .aboutHistory__itemDesc {
                position: absolute;
                left: 900px;
                top: 110px;
                width: 500px;
                font-size: 24px;
                color: #FFFFFF;
            }
        }

        &.en {
            .aboutHistory__item {
                .aboutHistory__itemDesc {
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .aboutHistory {
        margin-top: 40px;

        .aboutHistory__title {
            font-size: 40px;
        }

        .aboutHistory__list {
            margin-top: 66px;

            .aboutHistory__item {
                margin-top: -8px;

                .aboutHistory__itemImg {
                    width: 720px;
                    height: auto;
                }

                .aboutHistory__itemDesc {
                    left: 600px;
                    top: 75px;
                    width: 333px;
                    font-size: 16px;
                }
            }
            &.en {
                .aboutHistory__item {
                    .aboutHistory__itemDesc {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


@media screen and (min-width:1281px) and (max-width: 1746px) {
    .aboutHistory {
        margin-top: 48px;

        .aboutHistory__title {
            font-size: 48px;
        }

        .aboutHistory__list {
            margin-top: 80px;

            .aboutHistory__item {
                margin-top: -10px;

                .aboutHistory__itemImg {
                    width: 864px;
                    height: auto;
                }

                .aboutHistory__itemDesc {
                    left: 720px;
                    top: 90px;
                    width: 400px;
                    font-size: 20px;
                }
            }

            &.en {
                .aboutHistory__item {
                    .aboutHistory__itemDesc {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}